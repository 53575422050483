.church-date-mini
  height: 218px
  border-radius: 18px
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2)
  position: relative
  display: inline-block
  width: 100%
  margin-bottom: 36px
  background-color: #fff
  &:hover
    img
      opacity: 0.9
  &__bg
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 2
    object-fit: cover
    border-radius: 18px
    object-position: center
  &__shadow
    position: absolute
    top: 0
    // left: 50%
    // margin-left: -48px
    z-index: 1
    right: 376px
  &__bottom
    position: absolute
    bottom: 30px
    left: 15px
    right: 15px
    z-index: 3
  &__pre
    align-items: flex-start
    display: flex
    justify-content: flex-start
    margin-bottom: 1px
  &__pre-text
    text-align: left
    color: #fff
    letter-spacing: -1px
    font: 400 14px/18px 'Open Sans', sans-serif
    &--small
      color: #00718B
  &__title
    text-align: left
  &__title-text
    color: #fff
    height: 60px
    letter-spacing: -1px
    text-align: left
    font: 700 21px/24px 'Open Sans', sans-serif
    &--small
      color: #00718B
  &--wide
    background-image: linear-gradient(359.03deg, #A68B49 21.63%, #CFB571 64.65%)
    .church-date-mini__bg
      object-fit: contain
      object-position: right
    .church-date-mini__bottom
      left: 40px
      bottom: 40px
  &--great
    background-image: linear-gradient(to right, #d7b21e, #dbb936, #dfc049, #e3c75a, #e7ce6b)

    .church-date-mini__pre
      margin-bottom: 10px
    .church-date-mini__pre-text
      font-size: 24px
    .church-date-mini__title
      word-break: break-word
      max-width: 280px
    .church-date-mini__title-text
      font-size: 33px
      line-height: 1
  &--wide.church-date-mini--small
    background-image: none
    background-color: #EEF9F9
  &__uzor
    position: absolute
    z-index: 1
    left: 0
    top: 0
    right: 0
    bottom: 0

.swiper-pagination-bullets
  position: absolute
  width: 100%
  bottom: 20px
  left: 0
  display: flex
  align-items: center
  justify-content: center
  z-index: 99
  .swiper-pagination-bullet
    height: 10px
    width: 10px
    border-radius: 50%
    position: relative
    &::before
      position: absolute
      content: ''
      background-color: #C4C4C4
      height: 7px
      width: 7px
      left: 1.5px
      top: 1.5px
      border-radius: 50%
    &:hover
      cursor: pointer
    &.swiper-pagination-bullet-active
      &::before
        background-color: #46ADA0
  .swiper-pagination-bullet + .swiper-pagination-bullet
    margin-left: 1px
