.privacy-policy-page
  position: relative
  padding-bottom: 50px

.privacy-policy
  padding-top: 20px
  .title-block
    margin-bottom: 28px
  .title
    font: 800 36px/39px 'Open Sans', sans-serif
  .sub-title
    color: #00718B
    font: 800 21px/39px 'Open Sans', sans-serif
    letter-spacing: -0.03em
    margin-bottom: 13px
  &__item
    & > span
      margin-bottom: 18px
      display: block
    h5
      font: 400 18px/39px 'Open Sans', sans-serif
      letter-spacing: -0.03em
      color: #00718B
      strong
        font: 800 18px/39px 'Open Sans', sans-serif
    p
      color: #3C5358
      font: 400 14px/24px 'Open Sans', sans-serif
      a
        color: #00718B
        text-decoration-line: underline
        &:hover,&:focus,&:active
          color: #00718B
    br
      margin-bottom: 15px

@media all and (max-width: 991.9px)
  .privacy-policy
    .title-block .title
      font-weight: 800
    .sub-title
      font: 800 18px/25px "Open Sans", sans-serif
  .privacy-policy__item h5 strong
    font: 800 16px/20px "Open Sans", sans-serif
