.notices-grid
    display: flex
    flex-wrap: wrap
    gap: 30px
    
.notice-item
    background-color: #E4F7F0
    border-radius: 10px
    display: flex
    align-items: center
    justify-content: flex-start
    padding: 17px 24px
    text-decoration: none
    background-position: right center
    background-size: contain
    background-repeat: no-repeat
    &--grid
        flex: 0 1 calc(50% - 15px)
        &:last-of-type
            flex: 1 1 auto            
    &+&:not(.notice-item--grid)
        margin-top: 20px
    &__img, &__img-placeholder
        border-radius: 50%
        object-fit: cover
        object-position: center
        height: 64px
        width: 64px
        margin-right: 15px
        overflow: hidden
        padding: 2px
        background-color: #fff
        border: 2px solid #0BA85D
    &__img-placeholder
        object-fit: none
    &__description
    &__fio
        color: #00718B
        font-weight: 700
        font-size: 15px
        line-height: 20px
        margin-bottom: 5px
    &__type
        font-weight: 400
        font-size: 10px
        line-height: 14px
        text-transform: uppercase
        color: #4F90A6
        display: flex
        align-items: center
        span
            display: flex
            margin-left: 8px
    &--rest
        background-color: #F3F3F3
        .notice-item__img, .notice-item__img-placeholder
            border: 2px solid #676F73
            filter: grayscale(1)
        .notice-item__fio, .notice-item__type
            color: #676F73