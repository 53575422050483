.header-area
  min-height: 65px
  position: fixed
  width: 100%
  top: 0
  background-color: #fff
  background-image: url("../../assets/images/pic-10.png")
  background-repeat: inherit
  background-size: contain
  background-position: center
  display: flex
  justify-content: center
  //margin-bottom: 30px
  z-index: 100
.os-host
  padding-top: 30px
.header
  position: relative
  max-width: 805px
  width: 100%
  display: flex
  align-items: flex-end
  &__content
    white-space: nowrap
    width: 100%
    display: flex
    align-items: flex-end
    justify-content: space-between
    padding-bottom: 5px
  &__logo-area
    display: inline-block
    position: relative
  &__page-info
    text-align: center
    color: #00718B
    font: 400 21px/36px 'Open Sans', sans-serif
    letter-spacing: -0.05em

.button-registration
  border-radius: 30px!important
  background-color: #46ADA0
  //max-width: 150px
  //width: 100%
  padding: 10px 33px!important
  margin-left: 10px!important
  span
    text-transform: uppercase
    font: 400 14px/18px "PT Sans", sans-serif
    @media all and (max-width: 991.9px)
      font: 400 12px/14px "PT Sans", sans-serif
  @media all and (max-width: 991.9px)
    padding: 5px 10px!important
