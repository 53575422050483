.react-datepicker-popper
  transition: none

.react-datepicker
  border: 1px solid #a8dbd9
  border-radius: 5px

.react-datepicker__month-container
  background-color: #fff !important
  border-radius: 5px

.react-datepicker__header
  border-bottom: none !important
  background-color: #fff !important

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before
  transition: none

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before
  border-top-color: #a8dbd9 !important

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header
  color: #00718B !important
  text-transform: uppercase

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected
  color: #fff !important

.react-datepicker-popper
  z-index: 10
  select
    background-color: #fff!important
    resize: none
    padding: 2px 2px
    color: #00718B
    font: 400 15px/17px "Open Sans", sans-serif
    border-top: none
    border-left: none
    border-right: none
    border-bottom: 2px solid #A8DBD9
    outline: none !important

.react-datepicker__day-name
  color: #00718B
  width: 27px
  height: 27px

.react-datepicker__day, .react-datepicker__time-name
  color: #00718B
  width: 27px
  height: 27px
  transition: 0.1s ease background-color
  &:hover
    background-position: center
    background-size: cover
    color: #fff
    background-color: transparent
    background-image: url("../../assets/icons/icon-24.svg")

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled
  color: #ccc

.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today
  color: #fff

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover,
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range
  background-color: transparent
  background-image: url("../../assets/icons/icon-24.svg")
  width: 27px
  height: 27px
  background-position: center
  background-size: cover

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before
  border-bottom-color: #A8DBD9

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after
  border-bottom-color: #fff

.react-datepicker__input-container
  input
    cursor: pointer
    &::placeholder
      font-size: 15px

.date-picker-area
  cursor: pointer
  position: relative
  //z-index: 2
  &__icon
    position: absolute
    bottom: 10px
    right: 10px
    pointer-events: none
.react-datepicker__day--today
  color: #00718B

.react-datepicker__day--selected
  color: #fff
