.support-content
  padding: 30px 65px

.notifications-content
  padding: 45px 40px
  .event__buttons
    margin-top: 100px
  .switch
    margin-left: auto

.data-password-content
  padding: 30px 40px

.categories-content
  padding: 25px 40px
  .input
    padding-left: 0
  .input-container__text
    text-align: left
    padding-left: 0
  .event__buttons
    margin-top: 30px

.textarea__support
  height: 280px

.notifications
  position: relative
  display: flex
  flex-direction: column

.notification
  margin-bottom: 25px
  &__content
    display: flex
    align-items: center
  &__icon
    flex: 0 0 50px
    height: 50px
    border: 3px solid #A8DBD9
    display: flex
    align-items: center
    justify-content: center
    margin-right: 15px
    border-radius: 50%
    padding: 5px
    img
  //width: 20px
  //height: 20px
  &__text
    padding-right: 10px
    color: #00718B
    word-break: break-all
    font: 400 15px/21px 'Open Sans', sans-serif
  &__mail
    font-weight: 700

.categories
  position: relative
  margin-bottom: 30px
  .category:last-child
    margin-bottom: 0

.form-create-category
  .input, .input-container__text
    padding-left: 0

.category
  position: relative
  width: 100%
  margin-bottom: 15px
  &__content
    position: relative
    display: flex
    align-items: center
  &__toggle
    padding: 10px 5px
    margin-right: -5px
    &:hover
      opacity: 0.8
  &__name
    padding-right: 10px
    color: #00718B
    word-break: break-all
    font: 600 18px/20px 'Open Sans', sans-serif
  &__info
    flex: 0 0 auto
    display: flex
    align-items: center
    margin-left: auto
  &__count
    color: #4F90A6
    font: 400 12px/39px 'Open Sans', sans-serif
  &__content
    display: flex
    align-items: center
  &__dots
    padding: 10px 0
    cursor: pointer
    z-index: 1
    position: relative
    margin-left: 20px
    .category__dot:first-child
      margin-left: 0
    .category__dot
      border-radius: 50%
      width: 4px
      background-color: #4F90A6
      height: 4px
      margin-left: 4px

.category__dots--rest
  .category__dot
    background-color: #A0A5A7
  .drop-window__button-icon
    path
      fill: #a4a9ab
  .drop-window__button span
    color: #676F73
  .drop-window__button:hover
    background: rgba(196, 196, 196, 0.2)
    .drop-window__button-icon path
      fill: #676F73
    span
      color: #393D40

@keyframes bubble-show
  0%
    top: 0
    opacity: 0
    transform: scale(0)
  100%
    top: 5px
    transform: scale(1)
    opacity: 1

.drop-window
  animation: bubble-show .2s ease-in
  transition: all .2s ease-in
  position: absolute
  // top: -6px
  top: 5px
  z-index: 5
  right: -15px
  &__content
    padding: 30px 0
    width: 250px
    height: 145px
    background-color: #FFFFFF
    border-radius: 10px
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2)
  &__button
    background: #FFFFFF
    transition: 0.2s ease all
    width: 100%
    padding: 10px 30px
    border-radius: 0
    justify-content: left
    span
      color: #659EB1
      font: 400 12px/16px 'Open Sans', sans-serif
      text-transform: uppercase
    &:hover
      background: rgba(168, 219, 217, 0.2)
      span
        color: #00718B
      .drop-window__button-icon
        path
          fill: #00718B
  &__button-icon
    transition: 0.2s ease all
  &__button-icon-area
    width: 20px
    height: 20px
    display: flex
    align-items: center
    justify-content: center
    margin-right: 20px
  .category__toggle
    position: absolute
    top: 5px
    right: 15px
