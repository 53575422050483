#floatingCirclesG {
  position: absolute;
  width: 22px;
  height: 22px;
  margin: auto;
  transform: scale(1);
  left: 50%;
  top: 50%;
  margin-left: -11px;
  margin-top: -11px;
}
.f_circleG {
  position: absolute;
  background-color: rgb(255, 255, 255);
  height: 4px;
  width: 4px;
  border-radius: 2px;
  -o-border-radius: 2px;
  -ms-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  animation-name: f_fadeG;
  -o-animation-name: f_fadeG;
  -ms-animation-name: f_fadeG;
  -webkit-animation-name: f_fadeG;
  -moz-animation-name: f_fadeG;
  animation-duration: 1.2s;
  -o-animation-duration: 1.2s;
  -ms-animation-duration: 1.2s;
  -webkit-animation-duration: 1.2s;
  -moz-animation-duration: 1.2s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
}
#frotateG_01 {
  left: 0;
  top: 9px;
  animation-delay: 0.45s;
  -o-animation-delay: 0.45s;
  -ms-animation-delay: 0.45s;
  -webkit-animation-delay: 0.45s;
  -moz-animation-delay: 0.45s;
}
#frotateG_02 {
  left: 3px;
  top: 3px;
  animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
}
#frotateG_03 {
  left: 9px;
  top: 0;
  animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  -ms-animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
}
#frotateG_04 {
  right: 3px;
  top: 3px;
  animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  -ms-animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
}
#frotateG_05 {
  right: 0;
  top: 9px;
  animation-delay: 1.05s;
  -o-animation-delay: 1.05s;
  -ms-animation-delay: 1.05s;
  -webkit-animation-delay: 1.05s;
  -moz-animation-delay: 1.05s;
}
#frotateG_06 {
  right: 3px;
  bottom: 3px;
  animation-delay: 1.2s;
  -o-animation-delay: 1.2s;
  -ms-animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
}
#frotateG_07 {
  left: 9px;
  bottom: 0;
  animation-delay: 1.35s;
  -o-animation-delay: 1.35s;
  -ms-animation-delay: 1.35s;
  -webkit-animation-delay: 1.35s;
  -moz-animation-delay: 1.35s;
}
#frotateG_08 {
  left: 3px;
  bottom: 3px;
  animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  -ms-animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
}

@keyframes f_fadeG {
  0% {
    background-color: rgb(0, 113, 139);
  }
  100% {
    background-color: rgb(255, 255, 255);
  }
}
@-o-keyframes f_fadeG {
  0% {
    background-color: rgb(0, 113, 139);
  }
  100% {
    background-color: rgb(255, 255, 255);
  }
}
@-ms-keyframes f_fadeG {
  0% {
    background-color: rgb(0, 113, 139);
  }
  100% {
    background-color: rgb(255, 255, 255);
  }
}
@-webkit-keyframes f_fadeG {
  0% {
    background-color: rgb(0, 113, 139);
  }
  100% {
    background-color: rgb(255, 255, 255);
  }
}
@-moz-keyframes f_fadeG {
  0% {
    background-color: rgb(0, 113, 139);
  }
  100% {
    background-color: rgb(255, 255, 255);
  }
}