.mt-0
  margin-top: 0!important

.mr-0
  margin-right: 0 !important

.mb-0
  margin-bottom: 0!important

.ml-0
  margin-left: 0 !important

.ml-auto
  margin-left: auto !important

.mt-5
  margin-top: 0.3125rem !important

.mr-5
  margin-right: 0.3125rem !important

.mb-5
  margin-bottom: 0.3125rem !important

.ml-5
  margin-left: 0.3125rem !important

.mt-10
  margin-top: 0.625rem !important

.mr-10
  margin-right: 0.625rem !important

.mb-10
  margin-bottom: 0.625rem !important

.ml-10
  margin-left: 0.625rem !important

.mt-15
  margin-top: 0.9375rem !important

.mr-15
  margin-right: 0.9375rem!important

.mb-15
  margin-bottom: 0.9375rem !important

.ml-15
  margin-left: 0.9375rem !important

.mt-20
  margin-top: 1.25rem!important

.mr-20
  margin-right: 1.25rem!important

.mb-20
  margin-bottom: 1.25rem!important

.ml-20
  margin-left: 1.25rem!important

.mt-25
  margin-top: 1.5625rem!important

.mr-25
  margin-right: 1.5625rem!important

.mb-25
  margin-bottom: 1.5625rem!important

.ml-25
  margin-left: 1.5625rem!important

.mt-30
  margin-top: 1.875rem!important

.mr-30
  margin-right: 1.875rem!important

.mb-30
  margin-bottom: 1.875rem!important

.ml-30
  margin-left: 1.875rem!important

.minh-auto
  min-height: auto
