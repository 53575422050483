.section-main-banner
  padding-top: 31px
  padding-bottom: 15px
  position: relative
  margin-bottom: 0px
  .main-banner__sub-title
    margin-bottom: 36px
.main-banner
  position: relative
  &__content
    //padding-top: 40px
    position: relative
  &__content-text
    position: relative
    max-width: 425px
    width: 100%
  &__title
    text-transform: uppercase
    color: #00718B
    font: 400 36px/45px 'Open Sans', sans-serif
    margin-bottom: 8px
  &__sub-title
    letter-spacing: -0.9px
    color: #00718B
    font: 800 36px/39px 'Open Sans', sans-serif
    margin-bottom: 24px
  &__text
    color: #3C5358
    font: 400 15px/27px 'Open Sans', sans-serif
  &__image-block
    position: relative
    right: 0
    top: 4px

.section-step
  margin-bottom: 45px
  .row
    margin: 0 -50px
    .col-12:last-child
      .step
        margin-bottom: 20px
  .main-banner__sub-title
    margin-bottom: 34px

.step
  position: relative
  margin-bottom: 20px
  @media all and (max-width: 767.9px)
    margin-bottom: 100px
  &__arrow-image
    position: absolute
    @media all and (max-width: 767.9px)
      transform: rotate(90deg)
  &__arrow-image-step-1
    right: -23%
    top: 44%
    @media all and (max-width: 767.9px)
      right: 47%
      top: auto
      bottom: -37%
  &__arrow-image-step-2
    right: -23%
    top: 20%
    @media all and (max-width: 767.9px)
      right: 39%
      top: auto
      bottom: -37%
  &__image-block
    position: relative
    height: 135px
    display: flex
    align-items: flex-end
    justify-content: center
    margin-bottom: 15px
  &__text, strong
    text-align: center
    color: #3C5358
    font: 400 15px/21px 'Open Sans', sans-serif
    strong, a
      color: #00718B
      font-weight: 800
      text-decoration-line: underline

.section-functional
  position: relative
  padding-bottom: 40px
  .main-banner__sub-title
    margin-bottom: 41px
  &__list
    display: flex
    margin: 0 -12px

.functional-item
  display: flex
  flex-direction: column
  align-items: center
  flex: 0 1 25%
  &__image-block
    height: 110px
    display: flex
    justify-content: flex-end
    margin-bottom: 30px
    flex-direction: column
    align-items: center
  &__text
    color: #3C5358
    font: 400 15px/21px 'Open Sans', sans-serif
    text-align: center

.section-near-holiday
  &__title-block
    margin-bottom: 30px
  &__title
    position: relative

.near-holiday-slider-area
  position: relative
  margin-bottom: 35px
  .swiper-slide
    display: flex
    justify-content: center
    width: 215px
  @media all and (max-width: 575.9px)
    margin-right: -15px
    margin-left: -15px
  &::after
    content: ''
    position: absolute
    top: 0
    left: -3px
    height: 100%
    width: 50px
    z-index: 2
    transform: scale(-1,1)
    background: linear-gradient(-90deg, #FFFFFF -0.95%, rgba(255, 255, 255, 0) 85%)
  &::before
    content: ''
    position: absolute
    top: 0
    right: -3px
    height: 100%
    width: 50px
    z-index: 2
    background: linear-gradient(-90deg, #FFFFFF -0.95%, rgba(255, 255, 255, 0) 85%)
  .swiper-backface-hidden .swiper-slide
    display: flex
    justify-content: center
.near-slider
  position: relative
  width: 215px!important
  height: 355px
  border-radius: 16px
  overflow: hidden
  display: flex
  //margin-right: 30px
  //@media all and (max-width: 575.9px)
  //  margin-left: 15px
  img
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
  &__content
    position: relative
    z-index: 1
    width: 100%
  &__date
    position: absolute
    bottom: 148px
    left: 23px
    align-items: center
    text-decoration-line: none
    display: flex
    flex-direction: column
  &__num
    color: #fff
    font: 700 30px/41px 'Open Sans', sans-serif
    &--small
      color: #00718B
  &__month
    color: #fff
    font: 700 10px/14px 'Open Sans', sans-serif
    letter-spacing: -0.03em
    text-transform: uppercase
    &--small
      color: #00718B
  &__bottom
    position: absolute
    bottom: 30px
    left: 15px
    right: 15px
  &__pre
    align-items: flex-end
    display: flex
    justify-content: flex-end
    margin-bottom: 1px
  &__pre-text
    text-align: right
    color: #fff
    letter-spacing: -1px
    font: 400 14px/18px 'Open Sans', sans-serif
    &--small
      color: #00718B
  &__title
    text-align: right
  &__title-text
    color: #fff
    height: 60px
    letter-spacing: -1px
    text-align: right
    font: 700 21px/24px 'Open Sans', sans-serif
    &--small
      color: #00718B

.section-memo
  margin-bottom: 60px

.memo
  border-radius: 20px
  padding: 38px 40px 50px 40px
  background: url("../images/pic-13.png"), linear-gradient(180deg, #00718B 42.29%, #46ADA0 94.69%)
  position: relative
  background-position: 24% -253%
  background-repeat: no-repeat
  &__content
    position: relative
    z-index: 1
  &__title-block
    margin-bottom: 8px
    .title
      color: #fff
      font: 700 36px/38px 'Open Sans', sans-serif
  &__text
    width: 100%
    max-width: 552px
    color: #fff
    font: 400 16px/24px 'Open Sans', sans-serif
    margin-bottom: 44px
  &__button
    border-radius: 5px
    text-transform: uppercase
    padding: 10px 40px
    display: inline-block
    text-align: center
    @media all and (max-width: 567.9px)
      padding: 10px
      width: 100%
    span
      color: #fff
      font: 700 18px/23px 'PT Sans', sans-serif
  &__image
    z-index: 0
    position: absolute
    bottom: -15px
    right: -15px

.section-info
  &__title
    position: relative
    img
      width: 100%!important
  &__title-block
    margin-bottom: 14px
  &__text
    letter-spacing: -0.03em
    color: #676F73
    font: 400 15px/27px 'Open Sans', sans-serif
    strong
      font-weight: 700

.skeleton
  width: 100%
  & > span:last-of-type
    margin-bottom: 0

.skeleton-list
  height: 65px
  margin-bottom: 15px

.skeleton-church-title
  height: 36px
  margin-bottom: 12px

.skeleton-church-date
  height: 430px

.church-date-skeleton
  height: 100%
  width: 100%
  padding: 20px 0

.skeleton-category
  height: 39px
  margin-bottom: 15px

.skeleton-history
  height: 150px
  margin-bottom: 30px
