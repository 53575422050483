html, body
  //height: 100%
  min-height: 100%
  min-width: 320px
  overflow-x: hidden
  font-family: 'Open Sans', sans-serif
  -webkit-overflow-scrolling: touch

#root

main
//overflow-x: hidden
.container-fluid
  position: relative

.os-viewport
  -webkit-backface-visibility: hidden
  -webkit-transform: translate3d(0, 0, 0)
//height: 100%
//padding-bottom: 180px

//.route-container
//  overflow-x: hidden

::-webkit-scrollbar
  width: 4px
/* ширина scrollbar */

::-webkit-scrollbar-track
  background: #e2f0ee
/* цвет дорожки */

::-webkit-scrollbar-thumb
  background-color: #80b8c5
  /* цвет плашки */
  border-radius: 0px
/* закругления плашки */
//border: 5px solid #00718B    /* padding вокруг плашки */

body
  //overflow: hidden
  display: flex
  flex-direction: column
//overflow-x: hidden


.min-h,
.view-block-size
//min-height: 650px

.modal-open
  .bottom-menu-area
  //padding-right: 4px
  .bottom-menu::after
    opacity: 0

img
  max-width: 100%

.container-fluid
  padding-left: 0
  padding-right: 0

.container
  max-width: 835px
  width: 100%
  padding-right: 15px
  padding-left: 15px

.input-link
  display: inline-block
  font: 400 18px/25px 'Open Sans', sans-serif
  color: #00718B
  margin-top: 15px
  padding-left: 6px
  text-decoration: none
  &:hover
    text-decoration: underline
  &:hover, &:active, &:focus
    color: #00718B

.input, .textarea
  background-color: #fff
  border-radius: 0
  resize: none
  //height: 40px
  padding: 6px 8px
  color: #00718B
  font: 400 18px/25px 'Open Sans', sans-serif
  border-top: none
  border-left: none
  border-right: none
  border-bottom: 2px solid #A8DBD9
  &::placeholder
    color: #4F90A6
    font: 400 15px/20px 'Open Sans', sans-serif
  &:focus
    outline: none
  &--rest
    color: #5A6266
    border-color: #D3D3D3
    &::placeholder
      color: #5A6266
  &:disabled
    color: #A8DBD9

.input-border
  border: 1px solid #A8DBD9
  border-radius: 0
  width: 100%
  &::placeholder
    color: #4F90A6
    font: 400 14px/20px 'Open Sans', sans-serif

.input-container
  .input, .textarea
    width: 100%
  &__text
    padding: 5px 8px
    text-align: center
    color: #4F90A6
    font: 400 12px/16px 'Open Sans', sans-serif
    &--rest
      color: #676F73
  .text-left
    text-align: left

.input-error
  font: 400 12px/15px 'Open Sans', sans-serif
  color: #D30522
  transition: 0.2s ease all
  max-height: 0px
  padding: 0
  overflow: hidden
  &--open
    padding: 3px 0
    max-height: 100px
    overflow: visible

.title-block
  position: relative
  display: flex
  align-items: center
  justify-content: space-between
  .title
    color: #00718B
    font: 700 27px/21px 'Open Sans', sans-serif
    padding-right: 50px
  img
    position: relative
    top: 0
    right: 0
  strong
    font-weight: 700

.checkbox input
  display: none

.checkbox
  label
    cursor: pointer
    position: relative
  &__cr
    cursor: pointer
    display: inline-block
    width: 18px
    position: relative
    flex: 0 0 18px
    height: 18px
    border: 1.5px solid #56A7B3
    background-color: #fff
    border-radius: 5px
    img, svg
      transform: scale(0.8)
      width: 1.125rem
      height: 0.8125rem
    &::before
      opacity: 0
      top: 0
      left: 0
      transition: 0.2s ease all
      width: 100%
      height: 100%
      background: url("../../assets/icons/icon-36.svg")
      background-position: center
      background-repeat: no-repeat
      content: ''
      position: absolute
  &__text
    cursor: pointer
    font: 400 14px/21px 'Open Sans', sans-serif
    color: #00718B
  &__link
    color: #00718B
    display: contents
    text-decoration-line: underline

.checkbox input:checked + .checkbox__cr::before
  opacity: 1

.navigation
  user-select: none
  position: relative
  z-index: 10
  display: flex
  justify-content: center
  margin-bottom: 35px
  &__list
    display: flex
    flex-wrap: wrap
  &__item
    position: relative
    margin-right: 45px
    &:last-child
      margin-right: 0
      &:before
        display: none
    &:before
      content: ''
      position: absolute
      height: 30px
      width: 1px
      background-color: #DCE4EC
      top: 5px
      right: -24px
  &__link
    transition: 0.2s ease all
    text-decoration-line: none
    color: #00718B
    letter-spacing: -0.05em
    font: 400 18px/36px 'Open Sans', sans-serif
    &:hover, &:active, &:focus
      color: #00718B
    &--21
      font-size: 21px
    &--rext
      color: #296297
    &--active
      font-weight: 700
    &.active
      transition: 0.2s ease all
      font-weight: 700
//.os-content
//  overflow-x: hidden
.os-scrollbar-horizontal
  display: none

.price
  position: relative
  &--rest
    position: relative
    .price__total
      color: #296297
    .price__rub
      color: #6491BB
  &__total
    display: inline-block
    font: 800 24px/33px 'Open Sans', sans-serif
    color: #00718B
  &__rub
    display: inline-block
    margin-left: 5px
    text-transform: uppercase
    font: 300 24px/33px 'Open Sans', sans-serif
    color: #4F90A6

.switch
  width: 50px
  flex: 0 0 50px
  height: 25px
  border-radius: 15px
  border: 2px solid #A8DBD9
  position: relative
  padding: 2px
  transition: 0.2s ease all
  &__circle
    transition: 0.2s ease all
    position: absolute
    left: 2px
    top: 2px
    width: 17px
    height: 17px
    border-radius: 50%
    background-color: #A8DBD9
    &--active
      left: 27px
      background-color: #00718B

.scroll-overlay
  pointer-events: none
  transition: 0.2s ease all
  position: fixed
  left: 0
  right: 0
  width: 100%
  height: 50px
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)
  //background: red
  z-index: 99
  display: flex
  flex-direction: column
  &__bg
    height: 20px
    width: 100%
    background-color: #fff
  &__gr
    height: 50px
    width: 100%
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)

.scroll-overlay-area
  position: relative

.main-page-theme .os-scrollbar.os-scrollbar-vertical .os-scrollbar-handle
  opacity: 1

.os-scrollbar-vertical
  right: -3px !important

.custom-theme .os-scrollbar-handle
  background: #00718B !important
  opacity: 0.5
  transition: 0.5s ease opacity !important
  width: 4px
  border-radius: 0 !important
  left: 1px

.os-scrollbar-track:before
  content: ''
  height: 100%
  width: 2px
  position: absolute
  left: 2px
  background: #C5E1DD

.theme-hover .os-scrollbar-handle
  opacity: 0.5 !important

.theme-hover:hover .os-scrollbar-handle
  opacity: 1 !important
  transition: 0.5s ease opacity !important

.swiper-custom.hide-pagination
  .swiper-pagination
    display: none
    @media all and (max-width: 767.9px)
      display: flex !important

.calendar-items-container
  padding-bottom: 1.5rem
//
.elipse
  height: 0px
  width: 100%
  position: relative
  opacity: 1
  transition: 0.3s ease opacity
  &::before
    pointer-events: none
    transform: translateX(-50%)
    left: 50%
    top: 42px
    z-index: 0
    //top: -100px
    position: absolute
    content: ''
    max-width: 700px
    width: 100%
    height: 500px
    //height: 100%
    background: radial-gradient(50% 50% at 50% 50%, rgba(197, 225, 221, 0.7) 0%, rgba(197, 225, 221, 0) 100%)
    @media all and (max-width: 575.9px)
      top: 0
  &--hide
    transition: 0.3s ease opacity
    opacity: 0

.mobile-link-area
  display: none
  font: 700 18px/25px 'Open Sans', sans-serif
  color: #00718B
  .select-categories-area__icon
    bottom: 8px
.link-app
  margin-bottom: 57px
  display: flex
  justify-content: center
@media all and (max-width: 991.9px)
  .mobile-link-area
    display: block
  .navigation
    overflow: hidden
    max-width: 390px
    width: 100%
    z-index: 10
    position: relative
    margin: 0 auto 20px auto
    min-height: 25px
    transition: 0.3s linear overflow
    &--open
      overflow: visible
      .select-categories-area__icon
        transform: rotate(0deg)
      .navigation__list
        //overflow: visible
        max-height: 999px
        transition: 0.3s linear max-height
    &__list
      flex-wrap: nowrap
      overflow: hidden
      max-height: 0
      transform: translateY(100%)
      background-color: #ffffff
      bottom: 0px
      padding: 20px 30px
      border-radius: 0 0 20px 20px
      border: 2px solid #A8DBD9
      border-top-color: transparent
      width: 100%
      position: absolute
      flex-direction: column
    &__item
      margin-bottom: 15px
      margin-right: 0
      &:before
        display: none
    &__link
      width: 100%
      display: block
      line-height: 25px
      &.active
        font-weight: 400
        color: #A8DBD9

//.cropper-crop
//  pointer-events: none
//.cropper-crop-box
//  width: 150px!important
//  height: 150px!important
