.calendar-right-area
  top: 0
  position: fixed
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(86, 103, 107, 0.8)
  //opacity: 1
  pointer-events: none
  z-index: 999
  -webkit-transition: all .1s ease
  -o-transition: all .1s ease
  transition: all .1s ease
  opacity: 0
  &--active
    opacity: 1
    pointer-events: all

.calendar-right-toggle
  display: flex
  align-items: center
  justify-content: flex-end
  &__text
    font-size: 13px
    text-transform: uppercase
    color: #00718B
    margin-right: 18px
    display: inline-block
  &__icon
    flex: 0 0 32px

.calendar-right
  height: 100%
  position: fixed
  right: 0
  top: 0
  max-width: 470px
  width: 100%
  overflow: hidden
  z-index: 1000
  transform: translateX(100%)
  -webkit-transition: all .2s cubic-bezier(0.41, 0.63, 0.62, 1.16)
  -o-transition: all .2s cubic-bezier(0.41, 0.63, 0.62, 1.16)
  transition: all .2s cubic-bezier(0.41, 0.63, 0.62, 1.16)
  background-position: center bottom
  background-size: contain
  background-color: rgb(255, 255, 255)
  background-repeat: no-repeat
  background-position-y: calc(100% + 64px)
  opacity: 0
  &--open
  //transform: translateX(0)
  &__content
    overflow-x: hidden
    position: relative
    width: 100%
    min-height: 100%
    padding: 40px 40px
  &__button-close
    padding: 5px
    position: absolute
    top: 15px
    right: 15px
    svg
      path
        fill: #46ADA0
        stroke: #46ADA0
  &__calendar
// padding: 0 45px

.calendar__choosen-date
  color: #00718B
  font-weight: 700
  font-size: 24px
  margin-bottom: 16px

.calendar-right
  .calendar__choosen-date
    margin-bottom: 9px
  .church-date-mini
    margin-top: 7px
  .os-host
    padding: 0 15px 10px 15px
    margin: 0 -15px
  .scroll-overlay
    top: 0
    position: absolute
  .swiper
    margin: 0 -7px
    padding: 0px 7px
//.modal-open
//  .scroll-overlay
//    transition: 0.2s ease top
//    top: -60px
@media (max-width: 450px)
  .calendar-right
    .calendar__header
      width: 100%
      justify-content: center !important
