.draft-area
  //min-height: 69vh
  &::before
    top: -170px
    @media all and (max-width: 575.9px)
      top: 0
  .event-view
    height: 100%
    //overflow: hidden
    background-color: #fff
    border-radius: 20px
    &__content
      padding: 35px 0
      min-height: 390px
      position: relative
      z-index: 1
      //background-color: #fff
  .note-content__form-scroll
    max-height: 320px
  .modal-main__footer
    border-radius: 0
    flex-direction: column
  &__button
    min-height: 44px
  &__content
    border-radius: 20px
    overflow: hidden
    position: relative
    background-color: #FFFFFF
    height: 100%

.animation
  transition: 0.2s ease all
  max-height: 0
  overflow: hidden
  &.open
    max-height: 100px
    overflow: visible

.draft-buttons
  z-index: 15!important
  position: fixed!important
  max-width: 450px
  width: 100%
  left: 50%
  transform: translateX(-50%)
