.event-area
  position: relative
  margin-bottom: 50px
  &__title
    color: #00718B
    margin-bottom: 45px
    text-align: center
    letter-spacing: -0.05em
    font: 700 30px/36px 'Open Sans', sans-serif

.event
  &__stub
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 20px
    border-radius: 10px
    height: 350px
    width: 100%
    background-color: #EFF8F6
  &__stub-content
    position: relative
    display: flex
    flex-direction: column
    align-items: center
  &__stub-image
    max-width: 80px
    height: 58px
    width: 100%
    margin-bottom: 15px
  &__stub-text
    color: #4F90A6
    font: 400 13px/18px 'Open Sans', sans-serif
  .textarea
    height: 39px
  .input-container__text
    text-align: left
  &__buttons
    margin-top: 50px
    margin-bottom: 20px
    display: flex
    justify-content: center
  &__button-create
    min-height: 44px
    padding: 12px
    max-width: 320px
    width: 100%
    span
      font: 400 15px/20px "Open Sans", sans-serif
  &__photo
    overflow: hidden
    position: relative
    height: 350px
    margin-bottom: 20px
    border-radius: 10px
    img
      border-radius: 10px
      position: relative
      width: 100%
      height: 100%
      object-fit: cover
  &__photo-button-area
    position: absolute
    z-index: 1
    right: 20px
    bottom: 20px
  &__photo-button
    border-radius: 5px
    padding: 6px 18px
    span
      font: 400 13px/18px "Open Sans", sans-serif

.cropper-dashed
  display: none

.event__stub-content--rest
  .event__stub-text
    color: #296297

.select-categories-area
  position: relative
  z-index: 1
  &__input
    user-select: none
    cursor: pointer
    position: relative
    .input
      cursor: pointer
      padding-right: 30px
  &__icon
    position: absolute
    top: auto
    bottom: 10px
    right: 10px
    transition: 0.2s ease all
    transform: rotate(180deg)
    &--open
      transform: rotate(0deg)

.select-categories-drop
  transition: 0.1s ease all
  //display: none
  position: absolute
  bottom: -4px
  width: 100%
  opacity: 0
  transform: translateY(100%)
  .os-host
    padding-top: 0 !important
  &__content
    position: relative
    padding: 19px 30px 32px 30px
    background-color: #FFFFFF
    border: 2px solid #A8DBD9
    border-top-color: transparent
    border-radius: 0 0 20px 20px
  &__scroll
    max-height: 120px
    overflow-y: auto
    width: 100%
  &__item
    padding: 0
    width: 100%
    max-width: 100%
    justify-content: space-between
    span
      max-width: 100%
      overflow: hidden
      text-overflow: ellipsis
      color: #00718B
      font: 600 18px/39px "Open Sans", sans-serif
  .form-create-category
    margin-top: 12px
  .event__buttons
    margin-top: 25px
    margin-bottom: 0

.input-container-dates
  display: flex
  align-items: baseline
  justify-content: space-between
  //margin: 0 -12px
  .input-container
    width: 100%
  &._rest
    .input-container
      width: 180px
      flex: 0 0 180px
      @media all and (max-width: 991.98px)
        width: 48%
        flex: 0 0 48%
//margin-right: 12px
//margin-left: 12px
.event-buttons-edit
  align-items: center
  .button
    margin-bottom: 10px
    &:last-child
      background-color: #D1E0DE
      margin-bottom: 0
      &:hover
        background-color: #BED3D1
      span
        color: #3C5358
