.calendar
  &__header
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 30px
    @media all and (max-width: 450px)
      width: 100%
      justify-content: space-between !important
  &__button-month
    padding: 0
    white-space: nowrap
    margin: 0 30px
    @media all and (max-width: 450px)
      margin: 0
    span
      color: #00718B
      font: 400 21px/30px "Open Sans", sans-serif
      text-transform: capitalize
      @media all and (max-width: 575.9px)
        font: 400 14px/17px "Open Sans", sans-serif
    strong
      font-weight: 700
  &__body
    display: flex
    flex-wrap: wrap
    flex-direction: column
    width: 100%
    margin-bottom: 35px
    &--thin
      margin-left: auto
      margin-right: auto
      max-width: 400px
  &__days
    display: flex
    justify-content: space-between
    .calendar-mini__day
      flex: 1 1 auto
  &__row:last-child
    margin-bottom: 0
  &__row
    display: flex
    flex-wrap: wrap
    margin-bottom: 12px

.calendar__button-month
  position: relative
  .react-datepicker-wrapper
    height: 100%
    opacity: 0
    position: absolute
    left: 0
    width: 100%
    top: 0
  .date-picker-calendar-area__icon
    pointer-events: none
    margin-left: 5px
    margin-right: 0 !important
    position: relative
  .react-datepicker__input-container,
  .react-datepicker__input-container input,
  .month-picker
    height: 100%
    width: 100%
  &--color
    span
      color: #338DA2
.swiper-custom
  margin: -7px -7px 0 -7px
  .swiper
    padding: 7px 7px 0 7px

.block-main
  padding: 20px 0 0 0
  position: relative
  background-color: #fff
  border-radius: 20px 20px 0 0
  &::after
    position: absolute
    content: ""
    left: 0
    bottom: -100px
    width: 100%
    background: #fff
    height: 100px
    pointer-events: none
.block-main--calendar
  padding: 20px 40px
  &::before
    content: ''
    left: 50%
    transform: translateX(-50%)
    width: 970px
    height: 740px
    position: absolute
    z-index: -1
    top: -94px
    background: radial-gradient(50% 50% at 50% 50%, rgba(197, 225, 221, 0.7) 0%, rgba(197, 225, 221, 0) 100%)
  .event-view__content-image1,
  .event-view__content-image2
    z-index: -1
