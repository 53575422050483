.alert-area
  position: fixed
  top: 100px
  z-index: 200
  right: 0
  display: flex
  flex-direction: column
  align-items: flex-end

.alert
  pointer-events: none
  padding: 0
  margin: 0
  border-radius: 0
  border: none
  margin-bottom: 20px
  margin-right: -10px
  &__content
    background-color: #34A853
    border-radius: 10px 0 0 10px
    padding: 30px 45px
    display: flex
    align-items: center
    @media all and (max-width: 991.9px)
      padding: 20px 25px
    &:hover
      cursor: pointer
  &__icon
    margin-right: 15px
  &__text
    font: 600 18px/20px 'Open Sans', sans-serif
    color: #fff
    @media all and (max-width: 991.9px)
      font: 600 14px/17px 'Open Sans', sans-serif

.alert__content--bad
  background-color: #D30522
