.church-date-area
  position: relative
  display: flex
  justify-content: center
  &__image
    position: absolute
    top: 0
    left: 50%
    transform: translateX(-50%)


.church-date
  position: relative
  z-index: 1
  max-width: 680px
  width: 100%
  display: flex
  align-items: center
  flex-direction: column
  margin-bottom: 50px
  &__title-block
    margin-bottom: 12px
    text-align: center
    .title
      color: #00718B
      font: 400 30px/36px 'Open Sans', sans-serif
  &__date-block
    text-align: center
  &__date
    letter-spacing: -0.03em
    color: #00718B
    font: 600 18px/30px "Open Sans", sans-serif
    margin-bottom: 20px
  &__image-block
    margin-bottom: 30px
    @media all and (max-width: 767.9px)
      margin-bottom: 20px
    img
      border-radius: 10px
  &__buttons
    max-width: 430px
    width: 100%
    display: flex
    margin: 0 -10px 0px -10px
    justify-content: space-between
  .button
    flex: 0 1 200px
    color: #fff
    margin: 0 0px 30px 0px
    padding: 14px
    span
      font: 400 15px/20px "Open Sans", sans-serif
  &__text
    color: #676F73
    font: 400 17px/27px 'Open Sans', sans-serif
