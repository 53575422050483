.button
  -webkit-appearance: none
  display: flex
  align-items: center
  justify-content: center
  background-color: transparent
  outline: none
  border: none
  margin: 0
  transition: 0.3s ease-in-out all
  padding: 10px 20px
  -webkit-border-radius: 10px
  -khtml-border-radius: 10px
  -moz-border-radius: 10px
  border-radius: 10px
  text-decoration-line: none
  cursor: pointer
  position: relative
  &:disabled
    background: #E7EEED
    &:hover
      background-color: #E7EEED
      span
        color: #B1C3C0
    span
      font-weight: 400
      color: #B1C3C0
  img
    margin-right: 10px
  span
    color: #fff
    font: 400 12px/16px 'PT Sans', sans-serif
  &--t1
    background-color: #46ADA0
  &--t2
    background-color: #00718B
    &:hover
      background-color: #006279
  &--t3
    background-color: #296297
    &:hover
      background-color: #14528B
  &--t4
    background-color: #D30522
    &:hover
      background-color: #B9001A
  &--t5
    background-color: #fff
    border: 2px solid #A8DBD9
    span
      color: #00718B
    &:hover, &:active, &:focus
      color: #00718B
      border: 2px solid #A8DBD9 !important

.button[type="file"]
  cursor: pointer
  position: relative
  input
    cursor: pointer
    width: 100%
    height: 100%
    left: 0
    top: 0
    position: absolute
    opacity: 0
  span
    display: inline-block
    cursor: pointer

.button-pdf
  max-width: 320px
  width: 100%
  height: 44px
  overflow: hidden
  padding: 0
  background-color: #DCE4EC
  justify-content: flex-start
  &__icon-block
    display: flex
    align-items: center
    justify-content: center
    height: 100%
    width: 48px
    flex: 0 0 48px
    background-color: #EA4C3A
    position: absolute
    left: 0
    top: 0
    pointer-events: none
  img
    margin-right: 0
  span
    width: 100%
    text-align: center
    color: #3C5358
    font: 400 15px/20px 'Open Sans', sans-serif
  &:hover
    background-color: #BED3D1
  &:disabled &__icon-block
    background-color: #E7EEED

.button-back
  border-radius: 30px
  padding: 9px 13px
  background-color: #fff
  border: 1px solid #A8DBD9
  img
    margin-right: 8px
  span
    color: #00718B
    text-transform: uppercase
    font: 400 12px/16px 'PT Sans', sans-serif
