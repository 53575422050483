.calendar-mini-area
  position: relative
  display: flex
  justify-content: center

.calendar-mini
  max-width: 625px
  margin-bottom: 45px
  width: 100%
  display: flex
  &__col
    flex: 1 1 auto
    cursor: pointer
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    position: relative
  &__day
    margin-bottom: 15px
    text-transform: uppercase
    color: #85C4C9
    font: 400 13px/18px 'Open Sans', sans-serif
    letter-spacing: -0.05em
    text-align: center
    &--active
      color: #00718B
  &__number
    background-position: center
    background-repeat: no-repeat
    background-size: 0
    transition: 0.1s ease all
    display: flex
    justify-content: center
    text-align: center
    color: #4F90A6
    width: 42px
    height: 42px
    align-items: center
    font: 600 21px/29px 'Open Sans', sans-serif
    position: relative
    z-index: 1

    &--other-month
      color: #85C4C9
      font: 400 15px/20.5px 'Open Sans', sans-serif
    &--active
      font: 700 48px/50px 'Open Sans', sans-serif
      color: #00718B
      transform: translateY(-3px)
    //&:hover
    //  &::before
    //    opacity: 1
    //    width: 42px
    //    height: 42px
    &::before,
    &::after
      background-repeat: no-repeat
      background-size: cover
      background-position: center
      left: 50%
      top: 50%
      transition: 0.2s ease all
      transform: translate(-50%, -50%)
      content: ''
      position: absolute
    &::after
      z-index: 2
      //display: none
      width: 42px
      height: 42px
      background-image: url("../icons/icon-11.svg")
  &__month
    text-align: center
    // display: none
    color: #00718B
    letter-spacing: -0.03em
    text-transform: uppercase
    font: 700 16px/22px 'Open Sans', sans-serif
    position: absolute
    bottom: -30px
    left: 0
    right: 0
    text-align: center

.calendar-mini__number
  &:after
    background-size: 0
.calendar-mini__number--holiday
  &:after
    background-size: 100%

.calendar-mini__col--select
  .calendar-mini__month
    display: inline-block

.calendar-dots-area
  z-index: 4
  position: absolute
  bottom: -4px

.calendar-dots
  display: flex
  justify-content: space-between

.calendar-dot
  position: relative
  display: inline-block
  width: 10px
  height: 10px
  border: 2px solid #fff
  border-radius: 50%
  &--h
    background-color: #0BA95D
  &--r
    background-color: #677075


.calendar-mini__number--holiday
  &::after
    display: inline-block
  &._holiday-small
    background-size: 100%
    z-index: 3
    position: relative
    opacity: 1
    width: 42px
    height: 42px
    transition: 0.2s ease all
    //background-size: 0
    background-image: url("../icons/icon-10.svg")
    &::after
      display: none

.calendar-mini__number--now
  position: relative
  z-index: 5
  background-size: contain
  color: #fff
  background-image: url("../icons/icon-24.svg") !important
  &::before,
  &::after
    display: none


.calendar-mini__number
  background-size: 0
  transition: 0.2s ease all
  &--now
    transition: 0.2s ease all
    background-size: 100%
