.event-view-area
  position: relative
  display: flex
  justify-content: center
  user-select: none
  z-index: 1

.event-view
  position: relative
  z-index: 10
  max-width: 450px
  width: 100%
  //padding-bottom: 50px
  .note-content__form .input-container
    padding: 0 75px
  &__edit
    z-index: 5
    width: 38px
    height: 38px
    position: absolute
    top: 20px
    right: 20px
    border-radius: 50%
    background-color: rgba(0, 0, 0, 0.5)
    display: flex
    justify-content: center
    align-items: center
  &__info-area
    position: absolute
    top: 0
    transform: translateY(-100%)
    left: 0
    display: flex
    justify-content: center
    width: 100%
  &__info
    padding: 7px 28px
    background-color: #0BA95D
    border-radius: 10px 10px 0 0
    span
      color: #fff
      font: 400 16px/21px 'Open Sans', sans-serif
    &--rest
      background-color: #296297
  &__content
    position: relative
  &__content-image1
    position: absolute
    right: -20px
    bottom: 0
  &__content-image2
    position: absolute
    left: -20px
    bottom: 0
    transform: scale(-1, 1)
  &__photo-block
    position: relative
    z-index: 1
    //max-width: 430px
    padding: 0 10px
    width: 100%
    height: 350px
    margin: 0 auto
    overflow: hidden
    border-radius: 20px 20px 0 0
  &__photo-content
    position: relative
    z-index: 1
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    overflow: hidden
    background-color: #EFF8F6
    border-radius: 20px 20px 0 0
    &--rest
      background-color: #DEDEDE
  &__photo
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    object-position: center
    object-fit: cover
    &--stub
      position: relative
      width: auto
      height: auto
  &__body
    display: flex
    flex-direction: column
    height: 345px
    top: -30px
    width: 100%
    position: relative
    z-index: 2
    border-radius: 20px 20px 0 0
    padding: 35px 45px 7px 45px
    background-color: #FFFFFF
  &__name
    word-break: break-word
    letter-spacing: -0.05em
    color: #00718B
    font: 400 24px/27px 'Open Sans', sans-serif
    margin-bottom: 5px
    strong
      font-weight: 700
    &--rest
      color: #5A6266
  &__comment
    word-break: break-all
    //max-height: 82px
    overflow-y: auto
    color: #676F73
    letter-spacing: -0.03em
    font: 400 15px/27px 'Open Sans', sans-serif
    margin-right: -20px
    padding-right: 20px
    @media all and (max-width: 575.9px)
      margin-right: -5px
      padding-right: 5px
  &__button
    padding: 12px
    max-width: 320px
    width: 100%
    margin: auto auto 0 auto
    span
      font: 400 15px/20px 'Open Sans', sans-serif

.event-info
  display: flex
  flex-wrap: wrap
  margin-bottom: 5px
  &__block
    display: flex
    align-items: center
    margin: 0 25px 10px 0
    &:last-child
      margin-right: 0
  &__image
    margin-right: 10px
  &__text
    color: #4F90A6
    word-break: break-all
    font: 400 12px/16px 'Open Sans', sans-serif
    text-transform: uppercase
    &--rest
      color: #676F73

.event-view-date
  display: flex
  align-items: center
  margin-bottom: 20px
  &__date1, &__date2
    position: relative
    margin-right: 15px
    letter-spacing: -0.03em
    font: 600 16px/30px 'Open Sans', sans-serif
    color: #00718B
    &::before
      background-color: #C4C4C4
      content: ''
      position: absolute
      right: -9px
      top: 8px
      width: 1px
      height: 15px
    &--rest
      color: #5A6266
  &__date2
    color: #4F90A6
    font-weight: 400
    &::before
      display: none
    &--rest
      color: rgba(103, 112, 117, 0.7)
