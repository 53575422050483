.modal-backdrop
  z-index: 100

.modal
  z-index: 100

.modal-dialog
  margin: 0
  //bottom: 150px
  position: relative
  top: auto
  right: auto
  left: auto
  width: 100%
  //height: calc(100% - 150px)
  height: 100%
  overflow-y: auto
  display: flex
  align-items: end

.modal.fade .modal-dialog
  transform: translateY(100%)

.modal.show .modal-dialog
  transform: translateY(0%)

.modal-content
  //height: 100%
  display: flex
  justify-content: center
  background-color: transparent
  flex-direction: row
  border: none

.modal-role
  padding-left: 0 !important
  width: 100%
  height: 100%
  display: flex !important
  justify-content: center
  overflow: hidden

.modal-window
  justify-content: center
  overflow: hidden
  max-width: 430px
  width: 100%
  position: relative
  display: flex
  align-items: flex-end
  &__button-close
    width: 38px
    height: 38px
    position: absolute
    top: 20px
    right: 20px
    padding: 0px
    background-color: rgba(120, 134, 137, 0.7)
    border-radius: 50%
    z-index: 20
  &__content
    padding: 35px 40px
  &__header
    position: relative
  &__body
    display: flex
    flex-direction: column
    justify-content: center
  &__footer
    background-color: #EFF8F6
    border-radius: 0
    padding: 20px 55px
    @media all and (max-width: 575px)
      padding: 15px
  &__title
    letter-spacing: -0.05em
    color: #00718B
    font: 700 27px/30px 'Open Sans', sans-serif
    margin-bottom: 40px
    padding-right: 15px
  &__button
    //max-width: 320px
    width: 100%
    span
      font: 700 18px/25px 'Open Sans', sans-serif

.login-modal-button
  min-height: 45px

.button-google
  border: 2px solid #A8DBD9
  width: 100%
  background-color: #ffffff
  img
    margin-right: 13px
  span
    color: #089099
    letter-spacing: -0.05em
    font: 400 16px/21px 'Open Sans', sans-serif

.modal-access-note
  &__content
    padding: 40px 65px 0 65px
    @media all and (max-width: 575px)
      padding: 15px
  &__title
    text-align: center
    color: #00718B
    letter-spacing: -0.05em
    font: 400 16px/27px 'Open Sans', sans-serif
    margin-bottom: 13px
  &__sub-title
    text-align: center
    margin-bottom: 45px
    color: #00718B
    letter-spacing: -0.05em
    font: 700 24px/27px 'Open Sans', sans-serif
  &__image-block
    display: flex
    justify-content: center
    margin-bottom: 50px
  &__image
    position: relative
    right: -25px
    max-height: 100%
  .button
    padding-top: 14px
    padding-bottom: 14px
    margin: 0 10px
    span
      font: 400 15px/20px 'Open Sans', sans-serif

.edit-category
  .modal-window__content
    padding-bottom: 30px
  .modal-window__title
    font: 400 24px/27px "Open Sans", sans-serif
    margin-bottom: 22px
    strong
      font-weight: 700
  .modal-window__button
    min-height: 48px
    span
      font: 400 15px/20px "Open Sans", sans-serif

.modal-delete
  .modal-window__content
    padding: 0
  .modal-window__header
    align-items: center
    display: flex
    justify-content: center
    min-height: 84px
    background-color: #D30522
    border-radius: 20px 20px 0 0
    padding: 25px 50px 20px 50px
    color: #FFFFFF
    font: 600 18px/38px 'Open Sans', sans-serif
  .modal-window__header-icon
    margin-right: 17px
    path
      fill: #fff
  .modal-window__body
    background-color: #fff
    padding: 20px 55px
  .button--t4,
  .button--t5
    padding-top: 14px
    padding-bottom: 14px
    min-height: 48px
  .button--t4
    span
      letter-spacing: -0.05em
      font: 400 15px/20px 'Open Sans', sans-serif
  .button--t5
    padding-top: 11.5px
    padding-bottom: 11.5px
    span
      color: #089099
      letter-spacing: -0.05em
      font: 400 16px/21px 'Open Sans', sans-serif

.modal-pay-area
  .modal-main__header
    height: 170px
  .modal-main__button
    min-height: 48px
    span
      font: 700 18px/25px "Open Sans", sans-serif

.modal-main
  .checkbox__text
    color: #676F73
    font: 400 13px/15px 'PT Sans', sans-serif
  .checkbox
    margin-bottom: 64px

.modal-main__header
  height: 170px

.bank-card-area
  padding: 0 65px

.bank-card
  border-radius: 20px
  overflow: hidden
  margin-bottom: 50px
  &__header
    background-color: #00718B
    background-image: url("../images/pic-23.svg")
    padding: 30px
    display: flex
    justify-content: center
    align-items: center
    &--rest
      background-color: #296297
  &__header-text
    position: relative
    color: #fff
    display: flex
    align-items: center
    .total-text
      margin-right: 8px
      font: 400 15px/20px 'Open Sans', sans-serif
    .total-price
      font: 400 30px/41px 'Open Sans', sans-serif
      strong
        font-weight: 800
  &__body
    background-color: #E4F7F0
    padding: 30px 20px 20px 20px
    &--rest
      background: #EAEDF1
      .input-border
        border-color: #A8BFDB
    .input-border
      margin-bottom: 10px
    .card-mm, .card-gg
      width: 60px
      margin-right: 10px
    .card-cvc
      width: 80px
      margin-left: auto

.checkbox--rest
  .checkbox__text
    color: #676F73
  .checkbox__cr
    border-color: #4F90A6
  .checkbox__link
    color: #296297

.modal-main
  border-radius: 20px 20px 0 0
  position: relative
  max-width: 430px
  width: 100%
  background-color: #ffffff
  &.modal-note
    background: transparent
  &__close
    position: absolute
    top: 20px
    right: 20px
    width: 38px
    height: 38px
    background-color: rgba(0, 0, 0, 0.5)
    display: flex
    align-items: center
    justify-content: center
    z-index: 1
    border-radius: 50%
    padding: 0
  &__header
    border-radius: 20px 20px 0 0
    position: relative
    height: 270px
    overflow: hidden
    &.HEALTH,
    &.REST
      background-position-y: 30px
      background-position: center
      background-repeat: no-repeat
    &.HEALTH
      background-color: #d2ede1
      background-image: url("../images/pic-24.svg")
    &.REST
      background-image: url("../images/pic-25.svg")
      background-color: #DCE4EC
    img
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: auto
      object-fit: cover
  &__content
    position: relative
    top: -20px
    border-radius: 20px 20px 0 0
    padding-top: 40px
    background-color: #ffffff
    display: flex
    justify-content: center
  &__footer
    position: relative
    z-index: 1
    background-color: #EFF8F6
    //border-radius: 20px 20px 0 0
    display: flex
    justify-content: center
    align-items: center
    padding: 20px 10px
  &__button
    //background: #00718B
    max-width: 320px
    width: 100%
    border-radius: 10px
    span
      font: 400 15px/20px 'Open Sans', sans-serif
  &__static
    background-color: #fff
    z-index: 1
    position: relative
    //height: 515px
    display: flex
    flex-direction: column
    justify-content: space-between
  &__hide
    //background-color: #fff
    //max-height: 620px
    //height: 100%
    display: flex
    flex-direction: column
    justify-content: space-between
    z-index: 2
    transition: 0.3s ease all
    position: absolute
    bottom: -300%
    width: 100%
    left: 0
    &--open
      bottom: 0

.modal-main__hide
  place-content: flex-end
  .modal-main__content
    padding-top: 0
    top: 0
  .modal-main__header
    height: 170px

.modal-main__hide--open
  .modal-main__content
    padding-top: 20px
    top: -20px

.modal-content
  height: 100%

.modal-main__footer--border-radius-0
  border-radius: 0

.modal-pay
  .modal-main__header
    height: 170px
  .modal-main__button span
    font: 700 18px/25px "Open Sans", sans-serif

.payment-page
  .modal-window__footer
    flex-wrap: nowrap
    display: flex
    padding: 20px 55px 10px 55px
    .button
      margin: 0 10px 10px 10px
  .history-area::after
    bottom: -100px

.modal-backdrop.show
  opacity: 0.85
  background-color: #56676B

.modal-login
  &__button
    height: 44px
    width: 100%
    span
      font: 400 18px/24px "Open Sans", sans-serif
  &__buttons
    margin-top: 25px
    display: flex
    flex-direction: column
    padding: 0 15px
    .button:not(:first-child)
      margin-top: 15px
  .modal-window__content
    padding-top: 28px
    padding-right: 38px
    padding-bottom: 20px
    padding-left: 38px
  .modal-window__title
    margin-bottom: 20px
  .input-link
    margin-top: 12px
    font: 400 15px/20px "Open Sans", sans-serif
  .input-container
    margin-bottom: 8px