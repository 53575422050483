.modal-note-area
  position: relative
  overflow: hidden
  max-height: 775px
  height: 100%
  //position: fixed
  //top: 0
  //left: 0
  //display: flex
  //justify-content: center
  //align-items: center
  //width: 100%
  //height: 100%
  //background-color: rgba(0,0,0, .2)


.note-content
  width: 100%
  &__title
    text-align: center
    color: #00718B
    letter-spacing: -0.05em
    font: 700 24px/27px 'Open Sans', sans-serif
    margin-bottom: 20px
    &--rest
      color: #296297
  &__form-scroll
    max-height: 300px
    overflow-y: auto
  &__form
    position: relative
    background-color: #fff
    padding: 0 40px
    width: 100%
    .input-container
      padding: 0 60px
    .input
      font-size: 18px
      text-align: center
