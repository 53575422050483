.lists-area
 padding: 35px 40px
 #floatingCirclesG
  position: relative
  margin: 100px auto 0 auto
  transform: scale(1.5)
.list-item-area
  position: relative
  z-index: 1
.list-item
 display: flex
 margin-bottom: 15px
 &__image-area
  flex: 0 0 64px
  width: 64px
  height: 64px
  border-radius: 50%
  overflow: hidden
  border: 2px solid #0BA85D
  padding: 2px
  display: flex
  align-items: center
  justify-content: center
  margin-right: 15px
  &--rest
   border-color: #676F73
  img
   border-radius: 50%
   width: 100%
   height: 100%
   object-fit: cover
 &__content
  padding: 5px 0
  display: flex
  flex-direction: column
 &__name
  text-decoration-line: none
  .firstname, .lastname
   word-break: break-all
   color: #00718B
   font: 400 18px/21px 'Open Sans', sans-serif
  .lastname
   font-weight: 700
 &__tag
  margin-top: 5px
  display: flex
  flex-wrap: wrap
  .event-info__text
   font-size: 12px
   line-height: 16px
 &__menu
  margin-left: auto

.item__name--rest
 .firstname, .lastname
  color: #5A6266

.list-item-line
 margin-bottom: 5px
 position: relative
 background-color: #fff
 &__date
  text-transform: uppercase
  position: relative
  z-index: 1
  font: 600 15px/36px "Open Sans", sans-serif
  color: #00718B
  padding-right: 15px
  background-color: #fff
 &::before
  content: ''
  position: absolute
  left: 0
  width: 100%
  height: 2px
  background-color: #A8DBD9
  top: calc(50% - 2px)
.list-item-line__rest
 .list-item-line__date
  color: #676F73
 &::before
  background-color: #D4D4D4
