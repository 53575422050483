.bottom-menu-area
  position: fixed
  width: 100%
  display: flex
  justify-content: center
  left: 0
  bottom: 0px
  right: 0
  z-index: 102
  flex-direction: column
  align-items: center
  background-color: #fff
.bottom-menu
  position: relative
  //padding: 20px 15px 15px 15px
  border-radius: 10px
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2)
  max-width: 900px
  width: 100%
  background-color: #00718B
  background-position: bottom
  //background-size: cover
  background-repeat: repeat-x
  background-image: url("../../assets/images/pic-9.png")
  &--not-gradient:after
    display: none
  &::after
    pointer-events: none
    content: ''
    position: absolute
    left: 0
    width: 100%
    top: 0
    opacity: 0.8
    transition: 0.1s ease opacity
    transform: translateY(-100%)
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)
    height: 80px
  &__link-block
    transition: 0.2s ease all
    position: relative
    padding: 17px 20px
    &::after,
    &::before
      content: ''
      transition: 0.2s ease all
      right: 0
      left: 0
      height: 5px
      position: absolute
      opacity: 0
      pointer-events: none
      z-index: 1
      width: 100%
      background-color: #C4EEEC
    &::after
      border-radius: 5px 5px 0 0
      top: -4px
    &::before
      border-radius: 0 0 5px 5px
      bottom: -4px
  &__link
    flex: 1 1 100%
    text-decoration-line: none
    display: flex
    align-items: center
    flex-direction: column
    position: relative
    &:hover, &.bottom-menu__link--active
      .bottom-menu__link-block
        background-color: #C4EEEC
        .bottom-menu__icon
          path
            fill: #00718B
        .bottom-menu__text
          color: #00718B
        &::after,
        &::before
          opacity: 1

  &__content
    height: 100%
    position: relative
    display: flex
    justify-content: space-around
  &__icon-area
    display: flex
    justify-content: center
    height: 25px
    width: 100%
    margin-bottom: 7px
  &__text
    white-space: nowrap
    text-align: center
    text-decoration-line: none
    color: #fff
    font: 400 11px/15px 'Open Sans', sans-serif

.bottom-menu-enter
  .icon3
    path
      fill: #ffffff
  .bottom-menu__text
    color: #fff
    font-weight: 700
//.icon1
//  path
//    fill: red
.bottom-menu-info
  margin-top: 17px
  margin-bottom: 17px
  width: 100%
  display: flex
  justify-content: center
  &__content
    max-width: 900px
    width: 100%
    padding: 0 40px
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
  &__text
    margin: 0 0 10px 0px
    color: rgba(103, 111, 115, 0.8)
    font: 400 11px/120% 'Open Sans', sans-serif
    a
      color: #00718B
      &:hover,&:focus,&:active
        color: #00718B
  &__soc
    position: relative
  &__soc-item:last-child
    margin-right: 0
  &__soc-item
    display: inline-block
    margin: 0 20px 10px 0

.modal-open
  padding: 0!important
  .bottom-menu-area
    background-color: transparent
  .bottom-menu-info__text,
  .bottom-menu-info__text a
    transition: 0.2s ease all
    color: rgba(255, 255, 255, 0.7)
  .bottom-menu-info__soc-item
    svg
      rect
        transition: 0.2s ease all
        fill: rgba(255, 255, 255, 0.5)
      path
        transition: 0.2s ease all
        fill: #788689
