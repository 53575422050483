.date-block-stub
  position: relative
  border-radius: 10px
  background-color: #EFF8F6
  &__content
    padding: 18px 50px 0px 50px
    display: flex
    align-items: center
    justify-content: space-between
    @media all and (max-width: 767.9px)
      &:not(.date-block-stub--mini)
        flex-wrap: wrap
        flex-direction: column
        justify-content: center
    &.date-block-stub--mini
      flex-wrap: wrap
      flex-direction: column
      justify-content: center
  &__info
    display: flex
    align-items: center
  &__image
    position: relative
    margin-right: 25px
  &__text
    text-transform: uppercase
    font: 700 18px/30px 'Open Sans', sans-serif
    color: #00718B
  &__button
    border-radius: 10px
    max-width: 280px
    width: 100%
    font: 400 15px/20px 'Open Sans', sans-serif
    color: #fff
    &:hover, &:active
      color: #fff

.list-notice-stub
  .date-block-stub
    background-color: #fff
