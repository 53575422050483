.history-area
  position: relative
  &::after
    position: absolute
    content: ''
    left: 0
    bottom: -85px
    transform: rotate(180deg)
    width: 100%
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 80%)
    height: 100px
    pointer-events: none
.history-content
  padding: 40px 35px 20px 35px
  //@media all and (max-width: 575.9px)
  //  padding: 20px 15px

.history-scroll
  height: 100%
  min-height: 300px
  //overflow-y: auto
  margin-right: -20px
  padding-right: 20px
  @media all and (max-width: 575.9px)
    margin-right: -5px
    padding-right: 5px
.history
  margin-bottom: 30px
  background: #EFF8F6
  border-radius: 10px
  .event-info__text--rest
    color: #6491BB
  &--rest
    background: #E7EEF4
  &__content
    padding: 20px 30px 10px 30px
  &__header
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    position: relative
    span
      margin-bottom: 10px
    .title
      color: #00718B
      font: 700 18px/20px 'Open Sans', sans-serif
      &--rest
        color: #296297
    .date
      color: #00718B
      font: 400 13px/20px 'Open Sans', sans-serif
      &--rest
        color: #296297
  &__body
    position: relative
    display: flex

  &__image-block
    flex: 0 0 68px
    height: 68px
    display: flex
    align-items: center
    justify-content: center
    position: relative
    border-radius: 50%
    overflow: hidden
    border: 2px solid #0BA85D
    margin-right: 15px
    &--rest
      border-color: #676F73
  &__image
    width: 60px
    height: 60px
    border-radius: 50%
  &__info
    display: flex
    flex-direction: column
  &__name
    margin-bottom: 5px
    color: #00718B
    font: 400 15px/18px 'Open Sans', sans-serif
    &--rest
      color: #296297
    strong
      font-weight: 700
  &__footer
    margin-top: 25px
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
  &__button
    min-height: auto
    border-radius: 10px
    max-width: 165px
    width: 100%
    padding: 8px 10px
    background-color: #FFFFFF
    border: 1px solid #ADDDDB
    span
      color: #00718B
      font: 400 15px/17px 'Open Sans', sans-serif
    &--rest
      border-color: #96B9DA
      span
        color: #296297
  &__content-text
    color: #00718B
    font: 400 15px/17px 'Open Sans', sans-serif
