@media all and (max-width: 991.9px)
  .header-area
    min-height: 36px
  .header__logo-area
    width: 120px
  .header__content
    padding-left: 15px
    padding-right: 15px
    padding-bottom: 0
  .bottom-menu
    border-radius: 0
    background-position-y: 15px
  .bottom-menu__link-block
    padding: 10px 20px
  .bottom-menu__text
    font-size: 8px
    line-height: 11px
    text-align: center
  .date-block-stub__content
    padding: 10px 20px
  .date-block-stub__image
    margin-right: 15px
  .calendar-mini__number
    font-size: 18px
    line-height: 25px
  .calendar-mini__number::after,
  .calendar-mini__number--holiday._holiday-small,
  .calendar-mini__number
    width: 33px
    height: 33px
  .calendar-dots-area
    bottom: -6px
  .calendar-mini__month
    font: 600 18px/25px "Open Sans", sans-serif
  .calendar-mini__day
    font-size: 10px
  .main-banner__content
    padding-top: 0px
  .main-banner__image-block
    margin-top: 15px
  .title-block .title
    font: 700 21px/23px "Open Sans", sans-serif
  .title-block img
    width: 25px
  .section-info__title img
    max-width: 166px
  .section-info__title-block
    margin-bottom: 7px
  .section-info__text
    font-size: 14px
    line-height: 24px
  .memo
    padding: 20px

  .data-password-content,
  .support-content,
  .notifications-content,
  .categories-content,
  .lists-area,
  .history-content
    padding: 0px 0 15px 0
  .lists-content
    margin: 0 -15px
    padding: 0 20px 20px 15px
    .history-scroll
      margin-right: -25px
      padding-right: 25px
  .calendar-right__content
    padding: 15px 15px 40px 15px
  .event-view-area::before
    display: none
  .textarea__support
    height: 140px
  .event-view .note-content__form .input-container
    padding: 0 10px
  .bottom-menu__link-block::before
    bottom: -3px
  .bottom-menu__link-block::after
    top: -3px
  //.navigation
  //  margin-bottom: 0
  .bottom-menu-info
    display: none
  .event-view__body
    padding: 30px 15px
  .event-view__content-image1,
  .event-view__content-image2
    display: none
  .select-categories-drop__content
    padding: 15px
  .draft-area .event-view__content
    padding: 0px 0 150px 0
  .modal-delete .modal-window__header
    padding: 20px
    line-height: 20px
  .modal-delete .modal-window__body
    padding: 20px 50px
  .modal-window__content,
  .modal-login .modal-window__content
    padding-right: 15px
    padding-left: 15px
  .modal-login  .modal-window__footer
    padding: 15px 30px
  .modal-window__title
    margin-bottom: 20px
  .bank-card-area
    padding: 0 30px
  .bank-card
    &__header
      padding: 30px 5px
  .modal-main__hide
    height: 100%
    overflow: auto
  .modal-main
    .checkbox
      margin-bottom: 0
  .bank-card
    margin-bottom: 25px

  .notice-item--grid
    flex: 1 1 auto
  .calendar-mini__number--active
    font-size: 33px
  .elipse::before,
  .block-main--calendar::before
    display: none
  .block-main--calendar
    padding: 10px 0
  .modal-main__content
    padding-top: 20px
  .bottom-menu
    max-width: 100%
  .button-registration
    padding: 5px 10px
    width: auto
    max-width: initial
    span
      font-size: 11px
    img

      margin-right: 10px
  .main-banner__title,.main-banner__sub-title
    font-size: 30px
    line-height: 40px
  .draft-buttons
    max-width: 100%
  .draft-area__content
    border-radius: 0
  .section-main-banner .main-banner__sub-title
    margin-bottom: 20px
@media all and (max-width: 767.9px)
  .section-functional__list
    flex-wrap: wrap
  .functional-item
    margin-bottom: 10px
    flex: 1 1 50%

  .calendar-mini__day
    line-height: 13px
    margin-bottom: 10px
  .calendar-right-toggle__text,
  .header__page-info
    display: none
  .church-date .button
    margin: 0 0 15px 0
    flex: 1 1 auto
  .calendar-mini__month
    font-size: 11px
  .calendar-mini__month
    bottom: -20px
  .church-date-mini--wide
    .church-date-mini__pre-text
      font-size: 14px
      line-height: 18px
    .church-date-mini__title-text
      font-size: 21px
      line-height: 24px
    .church-date-mini__bottom
      left: 20px
      bottom: 20px
  .church-date-mini
    height: 190px
    .church-date-mini__shadow
      top: -20px
      right: 326px


@media all and (max-width: 650px)
  .main-banner
    flex-direction: column

@media all and (max-width: 450px)
  .functional-item
    flex: 1 1 100%
    margin-bottom: 30px
    &:last-child
      margin-bottom: 0
    &__image-block
      height: auto
      margin-bottom: 15px
  .event-view__photo-block
    padding: 0
  .event-view-page
    margin: 0 -15px
  .note-content__form
    padding: 0
  .church-date-mini__shadow
    display: none
  .church-date-mini--wide .church-date-mini__bg
    object-fit: cover

@media all and (max-width: 380px)
  .bottom-menu__link-block
    padding: 10px 5px

@media all and (min-width: 992px) and (max-height: 900px)
  .draft-area .note-content__form-scroll
    max-height: 250px
@media all and (min-width: 992px) and (max-height: 700px)
  .draft-area .note-content__form-scroll
    max-height: 200px
@media all and (max-width: 567.9px) and (max-height: 900px)
  .draft-area .note-content__form-scroll
    max-height: 355px
@media all and (max-height: 750px)
  .modal-main__header
    height: 250px
  .calendar-right__content
    //overflow: auto
    height: 100%
  //.draft-area .note-content__form-scroll
  //  max-height: 200px
  .modal-main .checkbox
    margin-bottom: 0
  .bank-card
    margin-bottom: 25px

@media all and (max-height: 680px)
  //.draft-area .note-content__form-scroll
  //  max-height: 150px

@media all and (max-width: 768.9px) and (orientation: landscape)
  .bottom-menu-info
    display: none !important
